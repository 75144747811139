<template>
  <div>
    <section class="header-kurumsal">

      <div class="foto"
           data-aos="fade-right"
           data-aos-offset="0"
           data-aos-delay="0"
           data-aos-duration="500"
           data-aos-easing="ease-in"
           data-aos-mirror="true"
           data-aos-once="false"
           data-aos-anchor-placement="top-center">
        <img :src="news.img"/>
      </div>

      <div class="baslik" >
        <h2>{{ news.heading }}</h2>
      </div>

      <img class="bgcizgi" src="../../../public/assets/img/haber-detay-header-bg.png" width="100%" height="auto" />
    </section>

    <section class="icerik"
             data-aos="fade-up"
             data-aos-offset="0"
             data-aos-delay="0"
             data-aos-duration="500"
             data-aos-easing="ease-in"
             data-aos-mirror="true"
             data-aos-once="false"
             data-aos-anchor-placement="top-center">

      <h3>{{ news.heading }}</h3>

      <p>
        {{ news.desc }}
      </p>

      <img class="bgcizgi" src="img/sayfa-cizgi-yesil-2.png" width="100%" height="auto" />

    </section>

  </div>
</template>
<script>
import iso from "../../axios";
export default {
  data(){
    return{
      news:[]
    }
  },
  created() {

  },
  methods:{
    pGet(){
      iso.get('news/'+this.$route.params.news)
          .then(response => {
            this.news = response.data
          })
          .catch(error => {
            console.log(error);
          })
    }
  },
  mounted() {
    this.pGet()
  },
  watch:{
    $route (to, from){
      this.pGet()
    }
  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter (to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>
<style scoped>
.header-haberdetay{
  position: relative;
  clear: both;
  width: 100%;
}
.header-haberdetay .bgcizgi{
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  z-index: -2;
}
.header-haberdetay .baslik{
  width: 40%;
  margin-top: 180px;
  margin-left:130px;
  float: right;
  color:white;
  text-align: left;
  z-index: 0;
}

.header-haberdetay .baslik h3{
  font-family: bukis;
  color:#fafcf4;
  font-size:30px;
  margin:8px 0px 8px 0px;
}
.header-haberdetay .baslik h2{
  font-family: elyazi;
  font-size: 60px;
  margin:8px 0px 8px 0px;
  color:#fafcf4;
}

.header-haberdetay .foto{
  width: 40%;
  float:left;
  margin-left: 10%;
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;
}

</style>
